import React from "react";
import logo from '../../assets/images/chapolin-logo.png';

import {
  FooterContainer,
  FooterButton,
  FooterLogo,
  StyledLink,
} from './Footer.style';

export default function Footer() {
  return (
    <FooterContainer>
      <FooterLogo src={logo} />

      <StyledLink to="/about-us">
        <FooterButton>Sobre a Chapolin</FooterButton>
      </StyledLink>

      <StyledLink to="/privacy-policy">
        <FooterButton>Política de Privacidade</FooterButton>
      </StyledLink>
      
    </FooterContainer>
  );
}