import React from "react";
import {
  BackgroundContainer
} from './Background.style'

interface BackgroundProps {
  children?: any;
}

export default function Background({
  children
}: BackgroundProps) {
  return(
    <BackgroundContainer>
      {children}
    </BackgroundContainer>
  );
}