import styled from '@emotion/styled';

export const MainContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 90%;
  justify-content: space-between;
  align-items: center;

  @media (min-width: 1048px) {
    width: 70%;
  }
  @media (min-width: 1429px) {
    width: 60%;
  }
`;

export const TextContent = styled.div`
  height: 370px;
  width: 40%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const TextContentP = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  display: flex;
  align-items: center;
  text-align: justify;
`;

export const TextContentH2 = styled.h2`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 38px;
  display: flex;
  align-items: center;
`;