import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomePage from './pages/Home';
import OurService from './pages/OurServices';
import AboutUs from './pages/AboutUs';
import PrivacyPolicy from './pages/PrivacyPolicy';

function Router() {
    return(
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<HomePage/>}/>
                <Route path='/our-services' element={<OurService/>}/>
                <Route path='/about-us' element={<AboutUs/>}/>
                <Route path='/privacy-policy' element={<PrivacyPolicy/>}/>
            </Routes>
        </BrowserRouter>
    );
}

export default Router;