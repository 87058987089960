import styled from '@emotion/styled';

export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 70px;
  margin-right: 70px;
  align-items: center;
  margin-bottom: 50px;
`;

export const Title = styled.h2`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 38px;
  width: 60%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
  justify-content: center;
`;

export const RegularText = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  display: flex;
  align-items: center;
  text-align: justify;
  color: #000000;
  @media (min-width: 1048px) {
    width: 70%;
  }
  @media (min-width: 1429px) {
    width: 60%;
  }
  @media (min-width: 1629px) {
    width: 50%;
  }
`;