import React from "react";
import {
  MainContent,
  TextContent,
  TextContentP,
  TextContentH2
} from './IllustratedContent.style';

interface IllustratedContentProps {
  title: string;
  text: string;
  image: string;
  isLeft: boolean;
}

export default function IllustratedContent({
  title,
  text,
  image,
  isLeft,
}: IllustratedContentProps) {
  return (
    <MainContent>
      {isLeft ? (
        <>
        <img className="WebImage" src={image} alt=""/>
        <TextContent>
          <TextContentH2>{title}</TextContentH2>
          <TextContentP>{text}</TextContentP>
        </TextContent>
        </>
      ) : (
        <>
        <TextContent>
          <TextContentH2>{title}</TextContentH2>
          <TextContentP>{text}</TextContentP>
        </TextContent>
        <img className="WebImage" src={image} alt=""/>
        </>
      )}
    </MainContent>
  );
}