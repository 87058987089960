import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;
  @media (max-height: 720px) {
    width: 90%;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 30px;
  width: 60%;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
`;

export const RegularButtons = styled.div`
  background-color: #F2F2F2;
  height: 40px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #000000;
  border: none;
  :hover {
    cursor: pointer;
  }
`;

export const GrayButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  align-items: center;
  color: #000000;
  background: #D9D9D9;
  border-radius: 10px;
  width: 211px;
  height: 40px;

  :hover {
    cursor: pointer;
  }
`;

export const Logo = styled.img`
  width: 97px;
  height: 123px;
`;