import React from "react";
import chatIcon from '../../assets/images/baloon-chat-icon.png';

import {
  HeaderContainer,
  RegularButtons,
  GrayButton,
  ButtonsContainer,
  Logo,
  StyledLink
} from './Header.style'

interface HeaderProps {
  logo?: string;
}

export default function HeaderPage({
  logo
}: HeaderProps) {
  return (
    <HeaderContainer>
      {logo ? <Logo src={logo}/> : <div></div>}
      <ButtonsContainer>
        <StyledLink to='/'>
          <RegularButtons>Início</RegularButtons>
        </StyledLink>
        <StyledLink to='/our-services'>
          <RegularButtons>Nossos Serviços</RegularButtons>
        </StyledLink>
        <StyledLink to="mailto:contato@chap0lin.com">
          <GrayButton>
            <img src={chatIcon} alt="Ícone de contato"/>
            Fale Conosco
          </GrayButton>
        </StyledLink>
        
      </ButtonsContainer>
        
    </HeaderContainer>
  );
}