import styled from '@emotion/styled';
import { Link } from "react-router-dom";

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 90%;
  gap: 16px;

  @media (min-width: 1048px) {
    width: 70%;
  }
  @media (min-width: 1429px) {
    width: 60%;
  }
`;

export const FooterButton = styled.button`
  border: none;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: justify;
  color: rgba(0, 0, 0, 0.8);
  heigth: 100%;
  :hover{
    cursor: pointer;
  }
`;

export const FooterLogo = styled.img`
  height: 144px;
  width: auto;
  align-items: center;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  display: flex;
  align-items: center;
  text-align: justify;
`;