import React from 'react';
import HeaderPage from '../../components/Header';
import ChapolinLogo from '../../assets/images/chapolin-logo.png';
import Background from '../../components/Background';
import {
  MainContent,
  Title,
  RegularText
} from './AboutUs.style';

export default function AboutUs() {
  return(
    <Background>
      <HeaderPage logo={ChapolinLogo}></HeaderPage>

      <MainContent>
        <Title>Sobre nós</Title>
        <RegularText>
          Bem-vindo à nossa Chapolin Solutions! 
          <br/><br/>
          Nós somos uma equipe apaixonada por criar entretenimento para pessoas de todas as idades e gostos.
          <br/><br/>
          Nosso objetivo é oferecer experiências inovadoras e envolventes aos nossos usuários, através de jogos online criativos e sites dinâmicos que atendam aos mais altos padrões de qualidade.
          <br/><br/>
          Nós nos dedicamos a oferecer um serviço personalizado, adaptado às necessidades e preferências individuais de cada um de nossos clientes. Seja a criação de jogos educacionais ou jogos de aventura, estamos sempre em busca de inovar e superar as expectativas.
          <br/><br/>
          Nosso trabalho é fruto de um esforço constante em pesquisa e desenvolvimento, aliado a um conhecimento técnico e criativo que nos permite transformar ideias em realidade.
          <br/><br/>
          Por isso, estamos sempre em busca de novos desafios e oportunidades para inovar e apresentar nossos produtos aos mais variados públicos. Seja você um usuário em busca de diversão ou um investidor em busca de inovação, estamos sempre prontos para apresentar o melhor de nosso trabalho em desenvolvimento jogos online e sites.
          <br/><br/>
          Então, venha conhecer nossos produtos, e faça parte da nossa história de sucesso!
        </RegularText>
      </MainContent>
    </Background>
  );
}