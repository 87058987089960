import styled from '@emotion/styled';

export const HomePageMainContent = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`;

export const InitialContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 90%;
  justify-content: space-around;
  padding-top: 50px;
  
  @media (min-width: 1048px) {
    width: 70%;
  }
  @media (min-width: 1429px) {
    width: 60%;
  }
`;

export const ChapolinLogo = styled.img`
  z-index: 0;
  height: 369px;
  width: 291px;
`;

export const HeroImage = styled.img`
  width: auto;
  height: 390px;
`;


