import styled from '@emotion/styled';

export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const InitialContent = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const BoxText = styled.div`
  width: 612px;
  height: 81px;
`;

export const Title = styled.h1`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
`;

export const Subtitle = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
`;

export const PortfolioTitle = styled.div`
  height: 110px;
  width: 100%;
  background: rgba(255, 255, 255, 0.6);
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PortfolioTriangle = styled.div`
  width: 0; 
  height: 0;
  border-left: 45vw solid transparent;
  border-right: 45vw solid transparent;
  border-top: 70px solid rgba(255, 255, 255, 0.6);
`;

export const PortolioProjectsContainer = styled.div`
  display: flex;
  padding-top: 20px;
  width: 50%;
  flex-direction: row;
  margin-bottom: 30px;
`;

export const PortfolioCarousel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 199px;
  height: 421px;
  left: 147px;
  top: 1348px;
  background: #FFFFFF;
  border-radius: 5px;
`;

export const PortfolioCarouselImage = styled.img`
  width: 153px;
  height: 194px;
  border-radius: 5px;
  margin-top: 22px;
  margin-bottom: 10px;
`;

export const PortfolioCarouselH3 = styled.h3`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
  margin: 0;
`;

export const PortfolioCarouselH2 = styled.h2`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
  position: relative;
  top: 25px;
`;

export const PortfolioCarouselP = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: justify;
  color: #000000;
`;

export const PText = styled.div`
  width: 183px;
  height: 133px;
`;
