import React from "react";
import HeaderPage from '../../components/Header';
import IllustratedContent from "../../components/IllustratedContent";
import Footer from "../../components/Footer";
import logo from '../../assets/images/chapolin-logo.png';
import heroImge from '../../assets/images/hero-image.png';
import homeImage1 from '../../assets/images/home-image1.png';
import homeImage2 from '../../assets/images/home-image2.png';
import Background from "../../components/Background";

import {
  HomePageMainContent,
  InitialContent,
  ChapolinLogo,
  HeroImage
} from './Home.style';

export default function HomePage() {
  return (
    <Background>

      <HeaderPage></HeaderPage>

      <HomePageMainContent>
        <InitialContent>
          <ChapolinLogo src={logo} />
          <HeroImage src={heroImge} />
        </InitialContent>

        <IllustratedContent 
          title={"Dê vida as suas ideias com um site para sua empresa"}
          text={"Crie sites com melhor desempenho usando templates responsivos para dispositivos móveis."} 
          image={homeImage1} 
          isLeft={true}
        />

        <IllustratedContent 
          title={"Seu produto em um aplicativo"}
          text={"Transformamos seu produto/serviço em um aplicativo para melhor experiência do seu cliente com a sua empresa."} 
          image={homeImage2} 
          isLeft={false}
        />

        <Footer></Footer>
      </HomePageMainContent>

    </Background>
  );
}