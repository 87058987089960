import React from "react";
import HeaderPage from '../../components/Header';
import IllustratedContent from "../../components/IllustratedContent";
import Footer from "../../components/Footer";
import hero1 from "../../assets/images/our-service-1.png";
import hero2 from "../../assets/images/our-service-2.png";
import DomQueShotLogo from '../../assets/images/dom-que-shot-logo.png';
import ChapolinLogo from '../../assets/images/chapolin-logo.png';
import Background from "../../components/Background";

import {
  MainContent,
  InitialContent,
  BoxText,
  Title,
  Subtitle,
  PortfolioTitle,
  PortfolioTriangle,
  PortolioProjectsContainer,
  PortfolioCarousel,
  PortfolioCarouselH2,
  PortfolioCarouselH3,
  PortfolioCarouselImage,
  PortfolioCarouselP,
  PText,
} from './OurServices.style';

export default function OurService() {
  return (
    <Background>
      <HeaderPage logo={ChapolinLogo}></HeaderPage>

      <MainContent>
        <InitialContent>
          <Title>Nós criamos a sua solução digital</Title>
          <BoxText>
            <Subtitle>Aqui você encontra uma equipe capacitada e pronta para tornar seu site e aplicativo realidade.</Subtitle>
          </BoxText>  
        </InitialContent>

        <IllustratedContent 
          title={"Responsividade e Multiplataforma"}
          text={"Todos os aplicativos e sites que desenvolvemos são responsivos e rodam em qualquer aparelho com suporte a navegadores."} 
          image={hero1} 
          isLeft={true}
        />

        <IllustratedContent 
          title={"Typescript, React, HTML e CSS."}
          text={"Nós trabalhamos com as linguagens mais usadas no mercado para aplicações web, e estamos prontos para o que você precisar."} 
          image={hero2} 
          isLeft={false}
        />

        <PortfolioTitle>
          <PortfolioCarouselH2>Conheça o nosso portfolio</PortfolioCarouselH2>
        </PortfolioTitle>
        
        <PortfolioTriangle/>

        <PortolioProjectsContainer>
          <PortfolioCarousel>
            <PortfolioCarouselImage src={DomQueShotLogo}/>
            <PortfolioCarouselH3>Dom Que Shot!</PortfolioCarouselH3>
            <PText>
              <PortfolioCarouselP>Um drinking game completo, com uma coletânea de minijogos e desafios para o grupo de amigos se divertirem em uma festa ou na mesa de um bar.</PortfolioCarouselP>
            </PText>
          </PortfolioCarousel>
        </PortolioProjectsContainer>

        <Footer/>
      </MainContent>
    </Background>
  );
}